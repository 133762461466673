import styles from "../../../styles/module/news.module.css";
import React, { useCallback } from "react";
import api from "../../../services/api";
import CarouselIMG from "../img/CarouselIMG";
import Loading from "../others/Loading";
import { useQuery } from "react-query";
import { imagemURL, urlADM, urlEmDeploy } from "../../../services/variables";

export default function News(props) {
  const idNoticia = props.idNoticias;

  const generateWhatsAppLink = useCallback(() => {
    return `${urlEmDeploy}/noticia/${idNoticia}`;
  }, []);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert("Link copiado para a área de transferência!"))
      .catch((err) => console.error("Erro ao copiar o link: ", err));
  }, []);

  const { data, isLoading, isError } = useQuery(
    `news-${idNoticia}`,
    async () => {
      const response = await api.post(`/news/unique/${idNoticia}`);
      return response.data.res;
    },
    { staleTime: 5 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Error loading news...</p>;
  }

  return (
    <div className={styles.container}>
      {data &&
        data.map((news) => (
          <div key={news.ID} className={styles.newsItem}>
            <div className={styles.header}>
              <img alt="notícias" src="/icons/seta-direita.svg" />
              <h2 className={styles.title}>{news.TITULO}</h2>
            </div>
            <div className={styles.metaInfo}>
              <p className={styles.category}>Categoria: {news.CATEGORIA}</p>
              <p className={styles.date}>
                Data: {new Date(news.DATA_POST).toLocaleDateString("pt-br")}
              </p>
              <p className={styles.views}>{news.VISUALIZACAO} visualizações</p>
            </div>
            <div className={styles.imageWrapper}>
              <img
                width={360}
                alt="imagem da notícia"
                src={
                  news.IMG === "logo.jpg"
                    ? "/imagens/logo.png"
                    : imagemURL + news.IMG
                }
              />
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: news.CORPO_TEXTO }}
            />
            {news.ARQUIVO && (
              <a
                className={styles.anexo_file}
                target="_blank"
                href={
                  urlADM +
                  `/downloads/other?n=${news.CNPJ.replaceAll("-", "")
                    .replaceAll("/", "")
                    .replaceAll(".", "")}&a=${news.ARQUIVO}&p=noticia`
                }
              >
                Clique aqui para baixar o anexo
              </a>
            )}

            {news.VIDEO && (
              <div className={styles.videoWrapper}>
                <iframe
                  width="560"
                  height="315"
                  className={styles.video}
                  src={news.VIDEO}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            <CarouselIMG img={news.MULTIPLE_IMG} />
            <div className={styles.shareSection}>
              <button
                onClick={() => copyToClipboard(generateWhatsAppLink())}
                className={styles.whatsappButton}
              >
                Compartilhe esta notícia
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}
