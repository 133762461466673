const idPortalApi = "1fea43fec0ccd7f2b0cbf55804aca8182177ab79"; //Raposa

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  //urlApi: `http://ec2-54-232-59-57.sa-east-1.compute.amazonaws.com:8004/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "raposa.ma.gov.br",
  NomePortal: "Raposa",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 9, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://www.raposa.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/GqzidtReruZotuMu7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15944.825819635797!2d-44.1038867!3d-2.4381455!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f6ea843772321d%3A0xabd6c84a23d4ba7e!2sC%C3%A2mara%20Municipal%20de%20Raposa!5e0!3m2!1spt-BR!2sbr!4v1711106475319!5m2!1spt-BR!2sbr`,
};
